import React from 'react'

import "./Difficulty.css"

import SettingsIcon from '@mui/icons-material/Settings';

import { useGame } from '../../../Context/GameContext';
import { usePoints } from '../../../Context/PointsContext';

import { GameDifficultyType } from '../../../types/GameDifficultyType';
import { GameStateType } from '../../../types/GameStateType';

const Difficulty: React.FC = () => {
  const { gameDifficulty, setGameDifficulty, setGameState } = useGame()
  const { handleChangeInGamemodeDifficulty } = usePoints()

  const difficultyOptions = [
    { value: GameDifficultyType.Any, label: 'Any' },
    { value: GameDifficultyType.Easy, label: 'Easy' },
    { value: GameDifficultyType.Medium, label: 'Medium' },
    { value: GameDifficultyType.Hard, label: 'Hard' },
  ];

  const changeGameDifficulty = (difficulty: GameDifficultyType) => {
    setGameDifficulty(difficulty)
    setGameState(GameStateType.Instructions)
    handleChangeInGamemodeDifficulty()
  }

  return (
    <div className='Difficulty'>
      <div className="difficulty-title">
        <SettingsIcon className='icon' sx={{ fontSize: 20 }} />
        <h2>Difficulty</h2>
      </div>
      <div className="difficulties-container">
        {difficultyOptions.map(({ value, label }) => (
          <button
            key={value}
            className={`difficulty-button ${gameDifficulty === value ? `active ${label}` : ''}`}
            onClick={() => changeGameDifficulty(value)}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default Difficulty